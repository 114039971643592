import React, { useState, useEffect } from 'react';
import { fetchAuthSession, fetchUserAttributes, signOut } from 'aws-amplify/auth';
import { useRouter } from '../assets/scripts/hooksLib';
// import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/login.scss';

/**
 * User login form
 * @returns {HTMLElement} html for login block
 */
export default function Login() {
    const router = useRouter();
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    // load user authentication state
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                // set the current/live canonical location url
                const location = window.location,
                    livelocation = `${location.protocol}//${location.host}${location.pathname}`,
                    canonical = document.querySelector('link[rel="canonical"]');
                canonical.setAttribute('href', livelocation);

                // check if the current user is logged in
                const session = await fetchAuthSession({ forceRefresh: true });
                // console.log(session);

                if (session.tokens) {
                    // a user must be logged in for this call to work
                    const user = await fetchUserAttributes();
                    console.log(user);
                    userHasAuthenticated(true);
                    setUser(user);
                }
            } catch (e) {
                if (e !== 'No current user') {
                    // onError(e);
                }
                console.log(e);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setUser, userHasAuthenticated]);

    /**
     * Log off website
     * @returns {void}
     */
    const handleLogout = async () => {
        await signOut({ global: true });

        userHasAuthenticated(false);

        router.history('/');
        scrollTop();
    };

    return (
        isAuthenticated ?
            <>
                {user ?
                    <div className="nav-item">
                        <a href='../me' className='nav-link' rel='nofollow'>
                            {user.name}
                        </a>
                    </div>
                    :
                    ''
                }
                <div className="nav-item" title='Logout'>
                    <a href='../logout.html' onClick={handleLogout} className='nav-link' rel='nofollow'>
                        <i className="fa-solid fa-power-off">&nbsp;</i>
                    </a>
                </div>
            </>
            : <>
                <div className="nav-item">
                    <a href="/signup.html" data-rr-ui-event-key="/signup.html" className="nav-link" rel="nofollow">Signup</a>
                </div>
                <div className="nav-item">
                    <a href="/login.html" data-rr-ui-event-key="/login.html" className="nav-link" rel="nofollow">Login</a>
                </div>
            </>
    );
}